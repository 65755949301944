import {useQuery, UseQueryOptions} from "@tanstack/react-query";
import {applicantsUrl} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";

interface ApplicantsParams {
  assigned_to?: string,
  hide_completed?: boolean,
  ordering?: string,
  search_term?: string,
  program_ids?: string[],
  current_page?: number,
  notification_id?: string;
}

interface ApplicantsResponse {
  data: any[];
  total_pages: number;
}


export const useQueryApplicants = (
  params: ApplicantsParams,
  options?: Omit<UseQueryOptions<ApplicantsResponse, Error>, 'queryKey' | 'queryFn'>
) => {
  const axios = useAxios();
  
  const handleRequest = () => {
    return axios.get(
      applicantsUrl,
      { params: params }
    )
    .then(response => response?.data ?? response);
  };

  return useQuery<ApplicantsResponse, Error>({
    queryKey: [
      'applicants', 
      params.search_term,
      params.notification_id, 
      params.program_ids?.join(','),
      params.ordering,
      params.assigned_to,
      params.hide_completed
    ],
    queryFn: handleRequest,
    ...options
  });
};
