import {
	User,
	Briefcase,
	GraduationCap,
	ClipboardText,
	PencilSimple,
	DownloadSimple,
	Archive,
} from "@phosphor-icons/react";
import moment from "moment";
import TextArea from "../../../../../components/UI/TextArea/TextArea";
import ButtonKMQ from "../../../../../components/UI/Button/ButtonKMQ";
import { ButtonTypes } from "../../../../../constants/ui/types/ButtonTypes";
import { useState } from "react";
import { useMutateUserInfo } from "../../../../../api/admin/useMutateUserInfo";
import ApplicantDocuments from "./ApplicantDocuments";
import { IPortfolio } from "../../../../../hooks/Context/PortfolioContext";
import "./applicant-information.scss";
import { useResumeDownload } from "../../../../../api/admin/useResumeDownload";

const ApplicantInformation = ({ data }: { data: IPortfolio }) => {
	const [notes, setNotes] = useState<string>(data.notes || "");
	const [isEditing, setIsEditing] = useState(false);
	const [tempNotes, setTempNotes] = useState<string>(data.notes || "");
	const { downloadResume } = useResumeDownload();
	const [isDownloadingResume, setIsDownloadingResume] = useState(false);
	const updatePortfolio = useMutateUserInfo();

	const formatDate = (date: string) => {
		return moment(date).format("MM/DD/YYYY");
	};

	const handleEditClick = () => {
		setIsEditing(true);
		setTempNotes(notes);
	};

	const handleCancel = () => {
		setIsEditing(false);
		setTempNotes(notes);
	};

	const handleSubmit = async () => {
		try {
			await updatePortfolio.mutateAsync({
				// @ts-ignore
				portfolio_id: data.id,
				notes: tempNotes,
			});
			setNotes(tempNotes);
			setIsEditing(false);
		} catch (error) {
			console.error("Error saving notes:", error);
		}
	};

	const handleDownloadResume = async () => {
		if (!data.id || isDownloadingResume || !data.resume_available) return;

		try {
			setIsDownloadingResume(true);
			await downloadResume({
				portfolio_id: data.id,
				portfolio_name: data.portfolio_user_name,
			});
		} catch (error) {
			console.error("Error downloading resume:", error);
		} finally {
			setIsDownloadingResume(false);
		}
	};

	return (
		<div className="applicant-info">
			<section className="info-section">
				<div className="applicant-profile-section">
					<div className="info-header">
						<User size={24} weight="regular" />
						<h2>Applicant's Information</h2>
					</div>
					<span
						className={`document-item-download ${!data.resume_available ? 'document-item-download-disabled' : ''}`}
						onClick={handleDownloadResume}
					>
						<DownloadSimple size={25} /> Download Resume
					</span>
				</div>
				<div className="info-content">
					<div className="experience-block">
						<div className="detail-list">
							<div className="single-line-item">
								<label>Email:</label>
								<span>{data.portfolio_email}</span>
							</div>
							<div className="single-line-item">
								<label>Account Created:</label>
								<span>
									{data.created_at ? formatDate(data.created_at) : ""}
								</span>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="info-section">
				<div className="info-header">
					<Briefcase size={24} weight="regular" />
					<h2>Work Experience</h2>
				</div>
				<div className="info-content">
					{data?.jobs?.map((job, index) => (
						<div key={index} className="experience-block">
							<h3>Work Experience {index + 1}</h3>
							{job.current_job && (
								<span className="currently-working">
									*currently working here
								</span>
							)}
							<div className="detail-list">
								<div className="single-line-item">
									<label>Company:</label>
									<span>{job.company}</span>
								</div>
								<div className="single-line-item">
									<label>Location:</label>
									<span>{job.location || "N/A"}</span>
								</div>
								<div className="single-line-item">
									<label>Start date:</label>
									<span>{formatDate(job.start_date)}</span>
								</div>
								{job.end_date && (
									<div className="single-line-item">
										<label>End date:</label>
										<span>{formatDate(job.end_date)}</span>
									</div>
								)}
								<div className="single-line-item">
									<label>Job title:</label>
									<span>{job.title}</span>
								</div>
							</div>
						</div>
					))}
				</div>
			</section>

			<section className="info-section">
				<div className="info-header">
					<GraduationCap size={24} weight="regular" />
					<h2>Education History</h2>
				</div>
				<div className="info-content">
					{data?.educations?.map((edu, index) => (
						<div key={index} className="experience-block">
							<h3>Degree Program {index + 1}</h3>
							<div className="detail-list">
								<div className="single-line-item">
									<label>Institution:</label>
									<span>{edu.institution_name}</span>
								</div>
								<div className="single-line-item">
									<label>Location:</label>
									<span>{edu.location}</span>
								</div>
								<div className="single-line-item">
									<label>Type of degree:</label>
									<span>{edu.degree_type}</span>
								</div>
								<div className="single-line-item">
									<label>Degree program:</label>
									<span>{edu.program}</span>
								</div>
								<div className="single-line-item">
									<label>Selected degree program:</label>
									<span>{edu.normalization_choice}</span>
								</div>
								<div className="single-line-item">
									<label>Graduation date:</label>
									<span>{formatDate(edu?.end_date || "")}</span>
								</div>
							</div>
						</div>
					))}
				</div>
			</section>

			<section className="info-section">
				<div className="info-header-notes">
					<div className="notes-section">
						<ClipboardText size={24} weight="regular" />
						<h2>Notes</h2>
					</div>
					{!isEditing && (
						<button
							className="edit-button"
							onClick={handleEditClick}
							aria-label="Edit notes"
						>
							<PencilSimple size={20} weight="regular" />
						</button>
					)}
				</div>
				<div className="notes-content">
					<TextArea
						value={isEditing ? tempNotes : notes}
						onChange={(e) => setTempNotes(e.target.value)}
						placeholder="Add a note"
						readOnly={!isEditing}
					/>
					{isEditing && (
						<div className="button-group">
							<ButtonKMQ onClick={handleCancel} type={ButtonTypes.Secondary}>
								Cancel
							</ButtonKMQ>
							<ButtonKMQ
								onClick={handleSubmit}
								disabled={
									!tempNotes.trim() ||
									tempNotes === notes ||
									updatePortfolio.isPending
								}
								type={ButtonTypes.Primary}
							>
								{updatePortfolio.isPending ? "Saving..." : "Submit"}
							</ButtonKMQ>
						</div>
					)}
				</div>
			</section>

			<section id="documents">
				<ApplicantDocuments />
			</section>
		</div>
	);
};

export default ApplicantInformation;
